<template lang="html">
  <div class="editor">
    <div ref="toolbar" class="toolbar"></div>
    <div ref="editor" class="text" :style="`height:${height}px;`"></div>
  </div>
</template>

<script>
  import E from './wang/wangEditor'
  import axios from 'axios'
  export default {
    name: 'EditorBar',

    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      value: {
        type: String,
        default: '',
      },
      isClear: {
        type: Boolean,
        default: false,
      },
      index: {
        type: Number,
        default: 0,
      },
      height: {
        type: Number,
        default: 600,
      },
    },
    data() {
      return {
        editor: null,
        info_: null,
        // token: '',
      }
    },

    watch: {
      isClear(val) {
        // 触发清除文本域内容
        if (val) {
          this.editor.txt.clear()
          this.info_ = null
        }
      },
      value: function (value) {
        console.log(
          `%c${'update'}`,
          'background:red;font-size:30px;color:white;font-weight:bloder'
        )
        // 好是好了，但是我忘了为什么要写这个判断
        //if (!this.editor.txt.html()) {
        this.editor.txt.html(value)
        // }
      },
    },
    mounted() {
      console.log(
        `%c${'mounted'}`,
        'background:red;font-size:30px;color:white;font-weight:bloder'
      )
      this.seteditor()
      this.editor.txt.html(this.value)
    },
    // created() {
    //   this.token = this.$store.getters['user/token']
    //   console.log(this.token)
    // },
    methods: {
      seteditor() {
        this.editor = new E(this.$refs.toolbar, this.$refs.editor)
        // 过滤word excel格式
        this.editor.customConfig.pasteFilterStyle = false // 关闭保留样式
        this.editor.customConfig.pasteText = false
        this.editor.customConfig.pasteTextHandle = function (content) {
          if (content == '' && !content) return ''
          // 其中content就是粘贴的原始的文本
          return removeFormatWrd(content)
        }
        function removeFormatWrd(html) {
          // html = html.replace(/<xml>[\s\S]*?<\/xml>/ig, "");
          // html = html.replace(/<style>[\s\S]*?<\/style>/ig, "");
          // html = html.replace(/<\/?[^>]*>/g, "");
          // html = html.replace(/[ | ]*\n/g, "\n");
          // html = html.replace(/&nbsp;/ig, "");
          // 上面代码是不要任何样式，纯文本

          // 下面代码，保留从相关文档中的粘贴过来时的样式
          // html = html.replace(/<\/?SPANYES[^>]*>/gi, "");//  Remove  all  SPAN  tags
          // html = html.replace(/<(\w[^>]*)  class=([^|>]*)([^>]*)/gi, "<$1$3");  //  Remove  Class  attributes
          // html = html.replace(/<(\w[^>]*)  style="([^"]*)"([^>]*)/gi, "<$1$3");  //  Remove  Style  attributes
          // html = html.replace(/<(\w[^>]*)  lang=([^|>]*)([^>]*)/gi, "<$1$3");//  Remove  Lang  attributes
          // html = html.replace(/<?xml[^>]*>/gi, "");//  Remove  XML  elements  and  declarations
          html = html.replace(/<\/?\w+:[^>]*>/gi, '') //  Remove  Tags  with  XML  namespace  declarations:  <o:p></o:p>
          // html = html.replace(/ /, "");//  Replace  the
          // html = html.replace(/<xml>[\s\S]*?<\/xml>/ig, '');
          // html = html.replace(/<html>[\s\S]*?<\/html>/ig, '');
          // html = html.replace(/<head>[\s\S]*?<\/head>/ig, '');
          html = html.replace(/<style>[\s\S]*?<\/style>/gi, '')
          // html = html.replace(/<html<body/ig, '<html><body');
          // html = html.replace(/<\/html<body>/ig, '</body></html>');
          // html = html.replace(/\n(\n)*( )*(\n)*\n/gi, '\n');
          return html
        }
        this.editor.customConfig.uploadImgShowBase64 = false // base 64 存储图片
        this.editor.customConfig.uploadImgMaxLength = 1 // 限制一次最多上传 1 张图片

        this.editor.customConfig.showLinkImg = false // 显示插入网络图片

        this.editor.customConfig.uploadVideoServer = true // 显示上传视频
        this.editor.customConfig.showLinkVideo = false // 显示插入视频
        // 是否使用自定义上传文件方法
        let customUpload = true
        if (customUpload) {
          this.editor.customConfig.customUploadVideo =
            this.editor.customConfig.customUploadImg = (
              filelist,
              insertLink
            ) => {
              let formData = new FormData()
              formData.append('file', filelist[0])
              formData.append(
                'X-Token',
                'b4e535fab9cc4c14ab1ccfa5d511dfe2ea2b70fa700d35e1fda3d5131d689c15'
              )
              axios
                .post(
                  'https://lingzhuang.gengduoke.com/adminapi/file/commonupload',
                  formData
                )
                .then((res) => {
                  console.log(res)
                  insertLink(res.data.data)
                })
            }
        } else {
          this.editor.customConfig.uploadImgServer =
            this.editor.customConfig.uploadVideoServer =
              'https://lingzhuang.gengduoke.com/adminapi/file/commonupload' // 配置服务器端地址
          if (!this.editor.customConfig.uploadVideoServer) {
            console.log('未配置上传方法,响应格式参照', {
              code: 1,
              filePath:
                'https://ztc-1300465688.cos.ap-beijing.myqcloud.com/chuangqirong/2021-09-24/b586d457-0f19-4cd0-b867-7bbff7105538.png',
            })
          }
          this.editor.customConfig.uploadImgHeaders = {} // 自定义 header
          this.editor.customConfig.uploadFileName = 'file' // 后端接受上传文件的参数名
          this.editor.customConfig.uploadImgMaxSize = 20 * 1024 * 1024 // 将图片大小限制为 20M
          this.editor.customConfig.uploadImgTimeout = 3 * 60 * 1000 // 设置超时时间
          this.editor.customConfig.uploadVideoHooks = {
            // 上传完成处理方法
            customInsert: function (insertVideo, result) {
              insertVideo(result.filePath)
            },
          }
          // this.editor.customConfig.uploadImgHooks = {
          //   fail: (xhr, editor, result) => {
          //     // 插入图片失败回调
          //   },
          //   success: (xhr, editor, result) => {
          //     // 图片上传成功回调
          //   },
          //   timeout: (xhr, editor) => {
          //     // 网络超时的回调
          //   },
          //   error: (xhr, editor) => {
          //     // 图片上传错误的回调
          //   },
          //   customInsert: (insertImg, result, editor) => {
          //     insertImg(result.filePath)
          //   },
          // }
        }
        this.editor.customConfig.onchange = (html) => {
          this.info_ = html // 绑定当前主键的值
          console.log(
            `%c${'change'}`,
            'background:red;font-size:30px;color:white;font-weight:bloder'
          )
          this.$emit('change', this.info_, this.index) // 将内容同步到父组件中
        }

        // 配置菜单
        this.editor.customConfig.menus = [
          'head', // 标题
          'bold', // 粗体
          'fontSize', // 字号
          'fontName', // 字体
          'italic', // 斜体
          'underline', // 下划线
          // "strikeThrough", // 删除线
          'foreColor', // 文字颜色
          'backColor', // 背景颜色
          //   'link', // 插入链接
          'list', // 列表
          'justify', // 对齐方式
          'quote', // 引用
          // "emoticon", // 表情
          'image', // 插入图片
          'video', // 插入视频
          // "table", // 表格
          // "code", // 插入代码
          'undo', // 撤销
          'redo', // 重复
        ]
        // 创建富文本编辑器
        this.editor.create()
      },
    },
  }
</script>

<style lang="css">
  .editor {
    position: relative;
    z-index: 0;
    width: 100%;
    margin: 0 auto;
  }
  .toolbar {
    border: 1px solid #ccc;
  }
  .text {
    min-height: 200px;
    border: 1px solid #ccc;
  }
</style>
