import request from '@/common/utils/request'
import Vue from "vue";

export function getList(params) {
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/yqchengnuo/list',
    method: 'get',
    params
  })
}
export function doEdit(data) {
    return request({
      url: Vue.prototype.$baseUrl + '/adminapi/yqchengnuo/update',
      method: 'post',
      data
    })
}
export function save(data) {
  return request({
    url: Vue.prototype.$baseUrl + '/adminapi/yqchengnuo/save',
    method: 'post',
    data
  })
}